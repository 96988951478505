import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { sideBarMenu, socialIcons } from '../../data/data'
import { useEffect } from 'react';
import gsap from 'gsap';
import './sidebar.scss'

const SideBar = () => {
    useEffect(() => {
        gsap.config({ trialWarn: false });
    
        let select = (s) => document.querySelector(s),
          toArray = (s) => gsap.utils.toArray(s),
          mainSVG = select('#mainSVG'),
          allEll = toArray('.ell'),
          colorArr = ['#359EEE', '#FFC43D', '#EF476F', '#03CEA4'];
    
        let colorInterp = gsap.utils.interpolate(colorArr);
    
        gsap.set(mainSVG, {
          visibility: 'visible',
        });
    
        function animate(el, count) {
          let tl = gsap.timeline({
            defaults: {
              ease: 'sine.inOut',
            },
            repeat: -1,
          });
          gsap.set(el, {
            opacity: 1 - count / (allEll.length),
            stroke: colorInterp(count / (allEll.length)),
          });
    
          tl.to(el, {
            attr: {
              ry: `-=${count * 2.3}`,
              rx: `+=${count * 1.4}`,
            },
            ease: 'sine.in',
          })
            .to(el, {
              attr: {
                ry: `+=${count * 2.3}`,
                rx: `-=${count * 1.4}`,
              },
              ease: 'sine',
            })
            .to(
              el,
              {
                duration: 1,
                rotation: -180,
                transformOrigin: '50% 50%',
              },
              0
            )
            .timeScale(0.5);
        }
        allEll.forEach((c, i) => {
          gsap.delayedCall(i / (allEll.length - 1), animate, [c, i + 1]);
        });
        gsap.to('#aiGrad', {
          duration: 4,
          delay: 0.75,
          attr: {
            x1: '-=300',
            x2: '-=300',
          },
          scale: 1.2,
          transformOrigin: '50% 50%',
          repeat: -1,
          ease: 'none',
        });
        gsap.to('#ai', {
          duration: 1,
          scale: 1.1,
          transformOrigin: '50% 50%',
          repeat: -1,
          yoyo: true,
          ease: 'sine.inOut',
        });
      }, []);


  return (
   
    <aside className='aside'>
        <div className="aside-wrapper">
            <Link to={"/"} className='logo-section'>
            
            

                <svg className="logo-container" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">
                    <defs>

                        <linearGradient cla="aiGrad" x1="513.98" y1="290" x2="479.72" y2="320" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#000" stop-opacity="0" />
                            <stop offset=".15" stop-color="#EF476F" />
                            <stop offset=".4" stop-color="#359eee" />
                            <stop offset=".6" stop-color="#03cea4" />
                            <stop offset=".78" stop-color="#FFC43D" />
                            <stop offset="1" stop-color="#000" stop-opacity="0" />
                        </linearGradient>

                    </defs>

                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />
                    <ellipse class="ell" cx="400" cy="300" rx="192" ry="192" />

                    <text id="ai" x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle" fontFamily="Arial" strokeLinecap="round" fontSize="72" stroke="url(#aiGrad)" strokeMiterlimit="-20" strokeWidth="2">
                     <tspan fill="#D9D9D9">ⵎⴰⵙⵏⵙⴻⵏ</tspan>
                        
                    </text>



                </svg>

                <script src='https://unpkg.co/gsap@3/dist/gsap.min.js'></script>

           
                                
                    {/*<span className='switch__color' style={{ fontSize: '1.8rem' }}>Massi</span>*/}
            </Link>
            
            
            <ul className='side-link'>{sideBarMenu.map((link, index) => {
                const {text, url, icon} = link
                return(
                    <li key={index}>
                        <NavLink to={url} className={({isActive}) => {
                            return isActive ? "nav__links active-links" : "nave__links"
                        }}>
                            {icon}
                            {text}
                        </NavLink>
                    </li>
                )
             })}
            </ul>
            <div className="social-icon">
                {socialIcons.map((icons, index) => {
                    const {icon, url} = icons
                    return(
                        <a href={url} key={index} rel="noreferrer" target="_blank">
                            {icon}
                        </a>
                    )
                })}
            </div>
        </div>
    </aside>
  )

}

export default SideBar